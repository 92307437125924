.nudge-to-download-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 2rem;
  background-image: url("../../../Assets/Images/nudgeBackground.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.nudge-to-download-image {
  width: 92vw;
  object-fit: contain;
}

.nudge-to-download-content {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;
  padding: 0 120px;
}

.nudge-bottom-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  max-width: 80%;
}
.nudge-top-text {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  padding-top: 1rem;
  text-align: center;
}

.nudge-down-button{
    border: none;
    background-color: #000;
    color: #fff;
    padding: 5px 60px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
}
