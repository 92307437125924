.filler-container {
    background-image: url('../../../Assets/Images/SmallEmployersBackground.png');
    background-size: cover;
    width: 100wh;
    height: 100vh;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .top-container, .bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top-container{
    margin-top: 2.25rem;
  }
  .top-text{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .filler-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    width:90vw;
  }
  
  .join-button {
    border: none;
  background-color: #000;
  color: #fff;
  padding: 5px 60px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 2rem;
  }
  
  .filler-image {
   height: 50vh;
  }
  
  .filler-image img {
    height: 90vh;
    width: auto;
  }
  