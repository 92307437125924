.testimonilas-small-circle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 480px;
  background-image: url("../../../Assets/Images/TestimonialsCircle.png");
  background-size: contain;
  background-position: center;
}
.swiper-item {
  position: relative;
  width: 100vw;
  height: 430px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.8);
  overflow: hidden;
}

.swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.8);
}

.swiper-slide-active {
  transform: scale(1);
}

@media (max-width: 600px) {
  .testimonilas-small-circle-container {
    width: 600px;
    height: 510px;
  }
}
@media (max-width: 550px) {
  .testimonilas-small-circle-container {
    width: 550px;
    height: 490px;
  }
}
@media (max-width: 475px) {
  .testimonilas-small-circle-container {
    width: 500px;
    height: 430px;
  }
  .swiper-item {
    width: 550px;
    height: 400px;
  }
}
@media (max-width: 425px) {
  .testimonilas-small-circle-container,
  .swiper {
    width: 450px;
    height: 400px;
  }
}
