.type-animation{
    animation: 
    typing 3.5s steps(32, end);
    /* blink-caret .5s step-end infinite; */
    overflow: hidden;
    white-space: nowrap;
    font-size: 75px;
    font-weight: 700;
    color: #000;
    margin: 8px 0;
    /* border-right: .15em solid #000; */
    padding: 0 16px;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #000 }
  }

  .about-mapout-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* background-image: url('../../../Assets/Images/SmallAboutMapout1.png'); */
    background: linear-gradient(113.69deg, rgba(255, 255, 255, 0.75) 4.65%, rgba(227, 255, 215, 0.75) 16.17%, rgba(216, 227, 252, 0.75) 73.06%, rgba(233, 217, 255, 0.75) 88.79%);
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    
  }

  
  .about-mapout-content {


   position: absolute;
   top: 8rem;
  }
  
  .about-mapout-text {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    padding: 0 4rem;
    line-height: 60px;
    text-align: center;

  }

  
  @media screen and (max-width: 768px) {
    .about-mapout-text{
    padding: 0 2rem;
    }
  }

  .type-animation {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    align-self: baseline;
    margin-left: 48px;
  }
  
  .about-mapout-image {
position: absolute;
bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center;
     border-radius: 30px 30px 0 0;
    height: 250px;
    padding:1rem;
  }
  
  .about-mapout-description {
    font-size: 24px;
    font-weight: 700;
    padding: 0 3rem;
    color: #000;

  }
  