.small-profile-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../Assets/Images/ProfileBackground.png");
  background-size: cover;
  background-position: top center;
  overflow: hidden;
}

.profile-image-container {
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-image {
  height: 50vh;
}

.profile-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.profile-info-content {
  text-align: center;
  width: 65vw;
  color: #000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.info-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.signup-button {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px 60px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .profile-image {
    height: 55vh;
  }
}
@media (max-width: 475px) {
  .profile-image {
    height: 45vh;
  }
}
