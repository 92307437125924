
.home-container-for-employers {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: linear-gradient(331.12deg, #dce5ff 5.07%, #C1D1FC 41.56%, #9db7ff 74.99%, #e1e9ff 86.13%);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  position: relative;
}


@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.welcome-animation-for-employers {
  width: 100vh;
  height: 100vh;
  border-radius: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 1;
  display: flex;
  animation: ripple1 5s infinite;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .welcome-animation-for-employers{
    width: 55vh;
    height: 55vh;
  }
} 

@media screen and (max-width: 768px) {
  .welcome-animation-for-employers{
    width: 55vh;
    height: 55vh;
  }
} 


@media screen and (max-width: 475px) {
  .welcome-animation-for-employers{
    width: 45vh;
    height: 45vh;
  }
} 


@keyframes ripple1 {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(0.9);
  }
}

.discover-container {
  background-color: white;
  width: 100vw;
  height: auto;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.small-discover-container {
  background-image: url("../../../Assets/Images/SmallForEmployersBackground.png");
  min-height: 100vh;
  overflow-y: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-x: hidden;
  max-width: 100%;
}
.discover-sub-container {
  margin-top: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 10;
}
.discover-text-content {
  padding: 0 3rem;
}
.discover-title {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.discover-subtitle {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  z-index: 10;
}

.discover-content-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding: 0 2rem;
  text-align: center;
}

.discover-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding-inline: 0.75rem;
  margin-top: 2rem;
}

.discover-content-button {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 5px 60px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.discover-cards {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding: 1rem;
  /* margin: 140px 48px 100px 48px; */
}

.discover-card {
  background-color: #ffffffa6;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  border-radius: 20px;
}

.discover-card img {
  height: 20px;
  align-self: baseline;
}

.discover-card-heading {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
.discover-card-desc {
  font-weight: 400;
  font-size: 12px;
  color: #000;
}

.discover-soon {
  display: flex;
  background-color: rgba(255, 255, 255, 0.45);
  margin-bottom: 1rem;
}

.discover-soon-content {
  display: flex;
  height: 2.5rem;
  align-items: center;
  overflow: hidden;
}

.discover-soon-content p {
  font-weight: 500;
  font-size: 14px;
  color: #4772f4;
  white-space: nowrap;
}

.discover-soon-separator {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #4772f4;
  margin: 0 16px;
}

.discover-content-svg {
  height: 1.5rem;
}

.discover-soon-content {
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide overflow */
}

.discover-soon-content p,
.discover-soon-content .discover-soon-separator {
  flex-shrink: 0; /* Prevent items from shrinking */
}

/* .discover-soon-content .discover-soon-separator {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #4772f4;
    margin: 0 16px;
  } */

@media (max-width: 475px) {
  .discover-text-content {
    padding: 0 2.75rem;
  }
}
@media (max-width: 380px) {
  .discover-text-content {
    padding: 0 2rem;
  }
}
@media (max-width: 320px) {
  .discover-text-content {
    padding: 0 1rem;
  }
}
