/* Existing CSS for desktop view */

.big-form-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-image: url("../../../Assets/Images/ContactUsBackground.png");
  background-size: cover;
  background-position: top center;
  padding: 4rem;
  overflow: hidden;
  color: #000;
}

.info-div {
  flex: 1;
  margin-right: 2rem;
  margin-top: 7rem;
}

.heading {
  font-size: 3rem;
  font-weight: 700;
}

.description {
  font-weight: 600;
  margin-top: 1rem;
  font-size: 1.3rem;
  width: 70%;
}

.form-div {
  flex: 1;
  margin-top: 3rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.35rem;
  font-weight: 500;
  font-size: 14px;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #dee2e3;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;
}

.form-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #dee2e3;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;
  resize: none;
  height: 100px;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: initial;
}

.radio-group {
  display: flex;
  justify-content: space-between;
}

.radio-box {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-right: 1rem;
  cursor: pointer;
}

.radio-box input[type="radio"] {
  appearance: none;
  width: 1.35rem;
  height: 1.35rem;
  background-color: white;
  border-radius: 4px;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;
}

.radio-box input[type="radio"]:checked::before {
  content: "✓";
  color: black;
  font-weight: bold;
  font-size: 0.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.submit-button {
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 40px;
  background-color: #000;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  width: 50%;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Additional CSS for mobile view */

@media (max-width: 767px) {
  .big-form-page {
    flex-direction: column;
    padding: 2rem 1.5rem 2rem 1rem;
    background-image: url("../../../Assets/Images/SmallContactUsBackground.png");
    background-position: center;
  }
  .heading {
    font-size: 30px;
  }

  .description {
    font-weight: 600;
    font-size: 18px;
    width: 100%;
  }

  .info-div {
    margin: 2rem 0;
  }
  .form-div {
    flex: 1;
    margin-top: 0;
  }
  .form-textarea, .form-input{
    border-radius: 4px;
  }
  .form-group {
    margin-bottom: 0.75rem;
  }
  .form-label {
    display: block;
    margin-bottom: 0.15rem;
    font-weight: 400;
    font-size: 14px;
  }
  .radio-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-box {
    margin-right: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 14px;
    padding: 0.15rem 0;
  }
  .radio-box input[type="radio"] {
    border-radius: 1px;
  }

  .submit-box {
    width: 100%;
    text-align: center;
  }
  .submit-button {
    width: 70%;
    border-radius: 8px;
  }
}
