.footer-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    padding: 3rem 0;
  }
  .footer-top{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    margin-left: 3rem;
  }
  .footer-mid{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    margin-left: 3rem;
    margin-top: 2rem;
  }

  .footer-logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .footer-logo-img {
    height: 2rem;
  }
  .footer-logo-img-name {
    height: 1rem;
  }
  
  .footer-tagline {
    color: #FFF;
    font-size: 1rem;
    font-weight: 600;
    line-height: 30px;
  }
  
  .footer-link {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
  
  .footer-separator {
    height: 0.5px;
    width: 100%;
    background-color: #FFF;
    margin-top: 16px;
  }
  
  .footer-bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 3rem;
    margin-top: 1rem;
  }
  
  .footer-copyright,
  .footer-legal-link {
    color: #FFF;
    font-size: 11px;
    font-weight: 400;
  }
  
  .footer-legal-links {
    display: flex;
    align-items: center;
  }
  
  .footer-link-divider {
    margin: 0 4px;
    color: #FFF;
  }

  .footer-social-container{
    margin-top: 1rem;
   display: flex;
   gap: 1rem;
  }
  .footer-social-icon{
    cursor: pointer;
    height: 2rem;
  }
  