.scroll_contents {
  height: 100vh;
  width: 100vw;
}

.red {
  background-color: #ff3d00;
}

.yellow {
  background-color: #ffff00;
}

.green {
  background-color: #05ff00;
}

.blue {
  background-color: #2835f8;
}
.small-feature-container {
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.small-ftr-lst-1,
.small-ftr-lst-2,
.small-ftr-lst-3 {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-ftr-lst-1 {
  background-color: #d8e3fc;
}
.small-ftr-lst-2 {
  background-color: #b9e4a6bf;
}
.small-ftr-lst-3 {
  background-color: rgba(217, 188, 255, 0.75);
}

.small-feature-animation-1 {
  width: 40vh;
  height: 40vh;
  border-radius: 40vh;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
  animation: ripple 5s infinite;
}
.small-feature-animation-2 {
  width: 60vh;
  height: 60vh;
  border-radius: 60vh;
  background-color: rgba(255, 255, 255, 0.35);
  position: absolute;
  z-index: 1;
  animation: ripple 5s infinite;
}
.small-feature-animation-3 {
  width: 80vh;
  height: 80vh;
  border-radius: 80vh;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 1;
  animation: ripple 5s infinite;
}
.small-feature-animation-img {
  width: 100%;
  z-index: 10;
  margin-top: 3rem;
}

.feature-animation {
  animation: ripple 5s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
