.termsOfUse {
  color: #1a1818;
  background-color: #fff;
  padding: 2rem;
}

.backButton {
  padding-top: 10px;
  padding-bottom: 10px;
}
.backButton button {
  font-weight: 250;
  padding: 10px;
  cursor: pointer;
}
.heading {
  font-size: 30px;
  line-height: 48px;
  margin-bottom: 20px;
}

.content {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.lastContent {
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.bold {
  background: linear-gradient(90deg, rgb(230, 234, 235), rgb(232, 236, 236));
  border: 1px solid rgb(232, 237, 238);
  padding: 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  margin: 5rem 0 2rem 0;
  position: sticky;
  top: 0;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #404040;
}

.small-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 36px;
  margin-bottom: 16px;

}

