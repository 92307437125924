.about-mapout-team-members-container {
    width: 100%;
    display: flex;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .modal-members {
    display: flex;
    position: fixed;
    z-index: 600;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .modal-content-success-members{
    background-color: #fefefe;
    padding: 40px;
    border: 1px solid #888;
    max-width: 450px;
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
    gap:1rem;
    width: 90%;
    margin: 0;
  }

  .modal-heading-container-members{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }