.about-career-container {
  height: 100vh;
  overflow: hidden;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #000;

}
.about-career-top-div{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5rem;
}
.about-career-bottom-div{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    font-size: 32px;
    font-weight: 700;
}