.about-mapout-mission-container {
  width: 100%;
  display: flex;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  background: linear-gradient(107.79deg, #FFFFFF 1.15%, rgba(225, 232, 250, 0.45) 18.32%, #E7F9DF 100%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.45));
  

}