.home-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: linear-gradient(-45deg,
      #ffffff,
      #98b4fc,
      #bdbffc,
      #e0cafc,
      #ffffff);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  position: relative;
}

.home-sub-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.home-sub-container-text {
  margin: 32px 0 32px 0;
  font-size: 24px;
  font-weight: 600;
  color: black;
}

.home-bottom-signup-text {
  /* margin: 64px 0; */
  font-size: 22px;
  font-weight: 600;
  color: black;
}

.home-sub-conatiner-download-buttons {
  margin-top: 32px;
  margin-bottom: 50px;
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
}

.home-play-store-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
  padding: 16px 52px;
  border-radius: 116px;
  cursor: pointer;
}

.home-apple-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
  padding: 16px 32px;
  border-radius: 116px;
  cursor: pointer;
  gap: 1rem;
}

.home-download-text {
  font-size: 20px;
  font-weight: 600;
}

.welcome-animation {
  width: 100vh;
  height: 100vh;
  border-radius: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 1;
  display: flex;
  animation: ripple1 5s infinite;
}

.mapout-large-logo {
  height: 7.5rem;
}

/* .apple-logo, */
.play-store-logo {
  height: 1.5rem;
  margin-right: 1rem;
}

.arrow-svg-download {
  height: 2rem;
  margin-left: 1rem;
}

.home-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  width: 40%;
  align-items: center;
}

.home-bottom-container-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  width: 80%;
  align-items: center;
}

.error-message {
  color: #ff0000;
}

.thank-you-message {
  font-weight: 500;
  font-size: 16px;
  color: #000000;

}

.home-bottom-signup-container {
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home-bottom-signup-container-error {
  border: 1px solid #ff0000;
  padding: 5px 10px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home-bottom-email-conatainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  width: 75%;
}

.home-custom-input {
  border: none;
  outline: none;
  background: none;
  font-size: inherit;
  width: 100%;
}

.home-custom-input::placeholder {
  color: black;
  font-weight: 500;
  font-size: 1rem;
}

.home-email-icon {
  width: 20px;
  /* Adjust size as needed */
  height: auto;
  /* Maintain aspect ratio */
  filter: invert(100%);
}

.home-sign-up-button {
  border: none;
  background-color: white;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
}

.home-sign-up-button-small {
  border: none;
  background-color: white;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
}

.features-listing-container {
  display: flex;
  align-items: center;
  gap: 27px;
  margin-top: 4.3rem;
}

.features-listing-container span {
  width: 3px;
  background-color: #fff;
  height: 18px;
}

.features-listing-container h4 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-align: left;
}


@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes ripple1 {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(0.9);
  }
}

@media (max-width: 768px) {
  .home-sub-container-text {
    margin: 32px 0 32px 0;
    font-size: 18px;
  }

  .home-bottom-signup-text {
    font-size: 16px;
    text-align: center;
  }

  .home-apple-button,
  .home-play-store-button {
    border: none;
    background-color: white;
    padding: 5px 30px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .home-download-text {
    font-size: 14px;
    font-weight: 500;
  }

  .mapout-large-logo {
    height: 3rem;
  }

  .welcome-animation {
    border-radius: 100vh;
    background-color: rgba(255, 255, 255, 0.3);
    width: 55vh;
    height: 55vh;
    display: flex;
    flex: none;
  }

  .home-sub-container {
    height: 77%;
  }

  .home-sub-conatiner-download-buttons {
    flex-direction: column;
    /* position: absolute;
    margin-bottom: -25rem; */
    gap: 1rem;
  }

  /* .apple-logo, */
  .play-store-logo {
    height: 1.5rem;
    margin: 0;
  }

  .arrow-svg-download {
    margin: 0;
    height: 1.5rem;
  }

  .features-listing-container {
    flex-direction: column;
    gap: 0.7rem;
  }

  .features-listing-container span {
    width: 40px;
    height: 3px;
  }

  .features-listing-container h4 {
    font-size: 12px;
    font-weight: 500;
  }

}

@media (max-width: 600px) {
  .home-sub-container {
    height: 65%;
  }
}

@media (max-width: 475px) {
  .home-download-text {
    font-size: 12px;
  }

  .home-sub-container {
    height: 53%;
  }

  .welcome-animation {
    border-radius: 100vh;
    background-color: rgba(255, 255, 255, 0.3);
    width: 45vh;
    height: 45vh;
    display: flex;
    flex: none;
  }
}